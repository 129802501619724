import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
  Paper,
  Grid,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import api from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const MarketingContentGenerator = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  // Constants for limit
  const GENERATE_LIMIT = 10; // Limit for unauthenticated users

  // State hooks
  const [selectedImages, setSelectedImages] = useState(
    location.state?.selectedImages || []
  ); // Images passed via routing
  const [uploadedFiles, setUploadedFiles] = useState([]); // Uploaded files
  const [uploadedFilePreviews, setUploadedFilePreviews] = useState([]); // Previews of uploaded files
  const [contentType, setContentType] = useState(""); // Type of content to generate
  const [generatedContent, setGeneratedContent] = useState(""); // Generated content
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error state
  const [remainingQueries, setRemainingQueries] = useState(GENERATE_LIMIT); // Remaining queries for unauthenticated users

  const [additionalInstructions, setAdditionalInstructions] = useState(""); // New state variable

  // Content options for selection
  const contentOptions = [
    "General Marketing Text",
    "Social Media Post",
    "Product Ad Copy",
    "Event Promotion",
    "Customer Engagement Message",
    "Email Subject Line",
    "Brand Story or Lifestyle Caption",
    "Inspirational or Motivational Quote",
    "Sales-Focused Text",
    "Brief Product Explanation",
  ];

  // Initialize remaining queries from localStorage for unauthenticated users
  useEffect(() => {
    if (!user) {
      const generateCount = parseInt(localStorage.getItem("generateCount")) || 0;
      setRemainingQueries(GENERATE_LIMIT - generateCount); // Update remaining queries
    }
  }, [user]);

  // Function to check if the user has reached the generate limit
  const checkGenerateLimit = () => {
    if (!user) {
      const generateCount = parseInt(localStorage.getItem("generateCount")) || 0;
      if (generateCount >= GENERATE_LIMIT) {
        alert(
          "You have reached the limit of free content generations. Please sign up or log in to continue."
        );
        navigate("/login"); // Redirect to login page
        return true; // Limit reached
      }
    }
    return false; // Limit not reached
  };

  // Handle image upload
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);

    // Generate previews for uploaded images
    const previews = files.map((file) => URL.createObjectURL(file));

    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    setUploadedFilePreviews((prevPreviews) => [...prevPreviews, ...previews]);

    // Reset the input value to allow re-uploading the same file if needed
    event.target.value = null;
  };

  // Cleanup object URLs to prevent memory leaks
  useEffect(() => {
    return () => {
      uploadedFilePreviews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [uploadedFilePreviews]);

  // Remove uploaded image
  const removeUploadedImage = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setUploadedFilePreviews((prevPreviews) => {
      // Revoke the object URL to prevent memory leaks
      URL.revokeObjectURL(prevPreviews[index]);
      return prevPreviews.filter((_, i) => i !== index);
    });
  };

  // Remove selected image
  const removeSelectedImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // Generate marketing content
  const generateMarketingContent = async () => {
    if (!contentType) {
      setError("Please select a content type");
      return;
    }
    if (selectedImages.length === 0 && uploadedFiles.length === 0) {
      setError("No images selected");
      return;
    }

    // Check if generate limit is reached
    if (!user && checkGenerateLimit()) {
      return; // Exit if the generate limit is reached for unauthenticated users
    }

    try {
      setLoading(true);
      setError("");
      setGeneratedContent("");

      const formData = new FormData();
      formData.append("content_type", contentType);

      // Append uploaded files
      uploadedFiles.forEach((file) => {
        formData.append("files", file);
      });

      // Append image URLs from selected images
      if (selectedImages.length > 0) {
        selectedImages.forEach((url) => formData.append("image_urls", url));
      }

      // Append additional instructions
      if (additionalInstructions) {
        formData.append("additional_instructions", additionalInstructions);
      }

      const response = await api.post("/generate-marketing-content", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Response from backend:", response.data);

      // Handle the response correctly
      if (response.data.marketing_content) {
        setGeneratedContent(response.data.marketing_content);

        // Increment generate count for unauthenticated users
        if (!user) {
          const generateCount = parseInt(localStorage.getItem("generateCount")) || 0;
          localStorage.setItem("generateCount", generateCount + 1);
          setRemainingQueries(GENERATE_LIMIT - (generateCount + 1)); // Update remaining queries
        }
      } else {
        setError("Unexpected response from server");
      }
    } catch (error) {
      console.error("Error generating content:", error);
      setError("Error generating content");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: "6rem", marginBottom: "4rem" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#550FCC",
          textAlign: "center",
          marginBottom: "2rem",
        }}
      >
        Generate Marketing Content
      </Typography>

      {/* Display remaining queries for unauthenticated users */}
      {!user && (
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ textAlign: "center", marginBottom: "1rem" }}
        >
          You have {remainingQueries} content generations remaining.
        </Typography>
      )}

      {error && (
        <Alert severity="error" sx={{ marginBottom: "1rem" }}>
          {error}
        </Alert>
      )}

      {/* Content Type Selector */}
      <FormControl fullWidth sx={{ marginBottom: "1.5rem" }}>
        <InputLabel id="content-type-label">Select Content Type</InputLabel>
        <Select
          labelId="content-type-label"
          id="content-type"
          value={contentType}
          label="Select Content Type"
          onChange={(e) => setContentType(e.target.value)}
        >
          {contentOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Additional Instructions TextField */}
      <TextField
        label="Additional Instructions"
        multiline
        rows={4}
        value={additionalInstructions}
        onChange={(e) => setAdditionalInstructions(e.target.value)}
        variant="outlined"
        fullWidth
        sx={{ marginBottom: "1.5rem" }}
      />

      {/* Buttons Container */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          marginBottom: "1.5rem",
        }}
      >
        {/* Image Upload Button */}
        <Button variant="outlined" component="label">
          Upload Images
          <input
            type="file"
            hidden
            accept="image/*"
            multiple
            onChange={handleImageUpload}
          />
        </Button>

        {/* Generate Content Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={generateMarketingContent}
          disabled={loading}
          sx={{ padding: "10px 20px", borderRadius: "8px" }}
        >
          {loading ? <CircularProgress size={24} /> : "Generate Content"}
        </Button>
      </Box>

      {/* Image Previews */}
      {(selectedImages.length > 0 || uploadedFilePreviews.length > 0) && (
        <Grid container spacing={2} sx={{ marginBottom: "2rem" }}>
          {/* Render selected images */}
          {selectedImages.map((url, index) => (
            <Grid item xs={12} sm={6} md={4} key={`selected-${index}`}>
              <Box position="relative">
                <img
                  src={url}
                  alt={`Selected Image ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                  }}
                />
                <IconButton
                  onClick={() => removeSelectedImage(index)}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}

          {/* Render uploaded images */}
          {uploadedFilePreviews.map((url, index) => (
            <Grid item xs={12} sm={6} md={4} key={`uploaded-${index}`}>
              <Box position="relative">
                <img
                  src={url}
                  alt={`Uploaded Image ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                  }}
                />
                <IconButton
                  onClick={() => removeUploadedImage(index)}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Generated Content */}
      {generatedContent && (
        <Paper
          elevation={3}
          sx={{
            padding: "1.5rem",
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: "1rem" }}
          >
            Generated Content
          </Typography>
          <Typography variant="body1">{generatedContent}</Typography>
        </Paper>
      )}
    </Container>
  );
};

export default MarketingContentGenerator;
