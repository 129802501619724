import React, { useEffect, useState } from "react";
import api from "../api";
import { useParams } from "react-router-dom";
import { Container, Typography, List, ListItem, ListItemText, Divider, Button } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { saveAs } from 'file-saver';  // You will need to install file-saver for CSV download

const Inferences = () => {
  const { modelId } = useParams();
  const [inferences, setInferences] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Fetch all inferences without filtering by date
  const fetchInferences = async () => {
    try {
      const response = await api.get(`/models/${modelId}/inferences`);
      setInferences(response.data.inferences);
    } catch (error) {
      console.error("Error fetching inferences:", error);
    }
  };

  // Handle CSV generation with date filtering
  const downloadCSV = () => {
    const csvRows = [
      ['File Name', 'Top Prediction', 'Timestamp']
    ];

    // Filter inferences by the selected date range
    const filteredInferences = inferences.filter((inference) => {
      const inferenceDate = new Date(inference.timestamp);
      const isAfterStart = !startDate || inferenceDate >= startDate;
      const isBeforeEnd = !endDate || inferenceDate <= endDate;
      return isAfterStart && isBeforeEnd;
    });

    filteredInferences.forEach(inference => {
      const topPrediction = inference.predictions[0];
      const formattedPrediction = `${topPrediction.class_name} (${(topPrediction.similarity * 100).toFixed(2)}%)`;
      const timestamp = new Date(inference.timestamp).toLocaleString();
      csvRows.push([inference.file_name, formattedPrediction, timestamp]);
    });

    const csvContent = csvRows.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `inferences_report_${new Date().toLocaleDateString()}.csv`);
  };

  useEffect(() => {
    fetchInferences();
  }, [modelId]);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Inference History
      </Typography>

      {/* Date Pickers for time range */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(params) => <input {...params} />}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(params) => <input {...params} />}
        />
      </LocalizationProvider>

      {/* Download CSV Button */}
      <Button variant="contained" color="primary" onClick={downloadCSV} style={{ margin: '10px 0' }}>
        Download CSV Report
      </Button>

      {inferences.length === 0 ? (
        <Typography>No inferences available for this model.</Typography>
      ) : (
        <List>
          {inferences.map((inference, index) => (
            <div key={index}>
              <ListItem>
                <ListItemText
                  primary={`File: ${inference.file_name}`}
                  secondary={`Top Prediction: ${inference.predictions.length > 0 ? 
                    `${inference.predictions[0].class_name} (${(inference.predictions[0].similarity * 100).toFixed(2)}%)`
                    : 'No predictions'}`}
                />
                <Typography variant="body2" color="textSecondary">
                  Timestamp: {new Date(inference.timestamp).toLocaleString()}
                </Typography>
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      )}
    </Container>
  );
};

export default Inferences;
