import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  Box,
  Tooltip,
  Dialog,
  DialogContent,
} from "@mui/material";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import api from "../api";
import AuthContext from "../context/AuthContext";

const SearchAndRetrieveWebImages = () => {
  const [query, setQuery] = useState(""); // Text-based query state
  const [imageFile, setImageFile] = useState(null); // Image file state for visual search
  const [results, setResults] = useState([]); // Search results state
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error state
  const [searchMode, setSearchMode] = useState("text"); // State for toggling between text and image search
  const [sortOrder, setSortOrder] = useState("similarity"); // Sorting option state
  const [pageNumber, setPageNumber] = useState(1); // Pagination state for page number
  const [hasMore, setHasMore] = useState(true); // State to track if there are more results
  const [selectedImages, setSelectedImages] = useState([]); // Track selected images for download
  const [imgSize, setImgSize] = useState(""); // State for image size filter
  const [imgLicense, setImgLicense] = useState(""); // State for image license filter
  const [selectedImage, setSelectedImage] = useState(null); // To store the image URL for the viewer

  const navigate = useNavigate();
  const { user } = useContext(AuthContext); // Get user from AuthContext

  const SEARCH_LIMIT = 100; // Set the search limit for unauthenticated users
  const pageSize = 15; // Number of results per page
  const [remainingQueries, setRemainingQueries] = useState(SEARCH_LIMIT); // Remaining queries for unauthenticated users

  useEffect(() => {
    if (!user) {
      const searchCount = parseInt(localStorage.getItem("searchCount")) || 0;
      setRemainingQueries(SEARCH_LIMIT - searchCount); // Update remaining queries
    }
  }, [user]);

  // Function to check if the user has reached the search limit
  const checkSearchLimit = () => {
    if (!user) {
      const searchCount = parseInt(localStorage.getItem("searchCount")) || 0;
      if (searchCount >= SEARCH_LIMIT) {
        alert(
          "You have reached the limit of free searches. Please sign up or log in to continue."
        );
        navigate("/login"); // Redirect to login page
        return true; // Limit reached
      }
    }
    return false; // Limit not reached
  };

  // Handle search mode switch
  const handleSearchMode = (event, newMode) => {
    if (newMode !== null) {
      setSearchMode(newMode);
      setResults([]); // Clear previous results when mode is switched
      setPageNumber(1); // Reset to first page
      setHasMore(true); // Reset the "load more" state
      setError("");
    }
  };

  // Function to handle sorting option change
  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  // Handle image size change
  const handleImgSizeChange = (event) => {
    setImgSize(event.target.value);
  };

  // Handle image license change
  const handleImgLicenseChange = (event) => {
    setImgLicense(event.target.value);
  };

  const handleImageClick = (imageLink) => {
    setSelectedImage(imageLink); // Set the clicked image URL to the state
  };

  // Function to fetch search results
  const searchImages = async (loadMore = false) => {
    if (!loadMore) {
      setLoading(true);
      if (!query && !imageFile) {
        setError("Please enter a search query or upload an image");
        setLoading(false);
        return;
      }
      setResults([]); // Clear results if this is a fresh search
      setPageNumber(1); // Reset page number for a fresh search
      setHasMore(true); // Reset "Load More" flag
    }

    // Check if search limit is reached
    if (!user && checkSearchLimit()) {
      return; // Exit if the search limit is reached for unauthenticated users
    }

    setError("");

    const formData = new FormData();
    if (searchMode === "text" && query) {
      formData.append("query_text", query);
    }

    if (searchMode === "image" && imageFile) {
      formData.append("file", imageFile);
    }

    try {
      const response = await api.post("/web-images/search", formData, {
        params: {
          page_size: pageSize,
          page_number: loadMore ? pageNumber : 1, // Increment page for "Load More"
          img_size: imgSize, // Include img_size in the params
          img_license: imgLicense, // Include img_license in the params
        },
      });

      if (loadMore) {
        setResults((prevResults) => [...prevResults, ...response.data.results]);
      } else {
        setResults(response.data.results);
      }

      if (response.data.results.length < pageSize) {
        setHasMore(false); // No more results to load
      }

      setPageNumber((prevPage) => prevPage + 1); // Increment page number for the next load

      // Increment search count for unauthenticated users
      if (!user) {
        const searchCount = parseInt(localStorage.getItem("searchCount")) || 0;
        localStorage.setItem("searchCount", searchCount + 1);
        setRemainingQueries(SEARCH_LIMIT - (searchCount + 1)); // Update remaining queries
      }
    } catch (error) {
      setError("Error retrieving images");
    } finally {
      setLoading(false);
    }
  };

  // Handle selection of an image (checkbox)
  const handleSelectImage = (imageLink) => {
    setSelectedImages((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(imageLink);
      const updatedSelection = isAlreadySelected
        ? prevSelected.filter((link) => link !== imageLink)
        : [...prevSelected, imageLink];

      console.log("Selected images:", updatedSelection);
      return updatedSelection;
    });
  };

  // Download selected images as ZIP
  const downloadSelectedImages = async () => {
    try {
      const response = await api.post(
        "/images/download-zip-web",
        { image_urls: selectedImages }, // Pass the selected image URLs
        {
          headers: { "Content-Type": "application/json" },
          responseType: "blob", // Ensure response is handled as a blob (binary data)
        }
      );

      // Create a URL for the ZIP file and trigger download
      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "selected_images.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // Clean up after download
    } catch (error) {
      console.error("Error downloading images:", error);
      setError("Error downloading images.");
    }
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: "5rem" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          marginBottom: "1rem",
          color: "#550FCC",
          textAlign: "center",
        }}
      >
        Search and Retrieve Web Images
      </Typography>

      {!user && (
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ textAlign: "center", marginBottom: "1rem" }}
        >
          You have {remainingQueries} search queries remaining.
        </Typography>
      )}

      <Grid container spacing={3}>
        {/* Right Column - Search Toggle, Input, Button, Results */}
        <Grid item xs={12} sm={9}>
          {error && (
            <Alert severity="error" sx={{ marginBottom: "1rem" }}>
              {error}
            </Alert>
          )}

          {/* Toggle button for selecting search mode */}
          <ToggleButtonGroup
            color="primary"
            value={searchMode}
            exclusive
            onChange={handleSearchMode}
            sx={{ marginBottom: "1.5rem" }}
            InputProps={{
              style: {
                borderRadius: "20px", // Add borderRadius here for the input field
              },
            }}
          >
            <ToggleButton value="text">Text Search</ToggleButton>
            <ToggleButton value="image">Visual Search</ToggleButton>
          </ToggleButtonGroup>

          {/* Image Size and License Filters - Placed Side by Side */}
          <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="img-size-label">Image Size</InputLabel>
                <Select
                  labelId="img-size-label"
                  value={imgSize}
                  label="Image Size"
                  onChange={handleImgSizeChange}
                >
                  <MenuItem value="">All Sizes</MenuItem>
                  <MenuItem value="Small">Small</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Large">Large</MenuItem>
                  <MenuItem value="Wallpaper">Huge</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="img-license-label">Usage Rights</InputLabel>
                <Select
                  labelId="img-license-label"
                  value={imgLicense}
                  label="Usage Rights"
                  onChange={handleImgLicenseChange}
                >
                  <MenuItem value="">Any</MenuItem>
                  <MenuItem value="Public">
                    <Tooltip title="Images that are in the public domain and free to use without attribution.">
                      <div>Public Domain</div>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem value="Share">
                    <Tooltip title="You can use the image as long as you give credit to the creator.">
                      <div>Free to Share and Use</div>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem value="ShareCommercially">
                    <Tooltip title="You can use the image for commercial purposes as long as you give credit to the creator.">
                      <div>Free to Share and Use Commercially</div>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem value="Modify">
                    <Tooltip title="You can modify the image as long as you give credit to the creator.">
                      <div>Free to Modify, Share, and Use</div>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem value="ModifyCommercially">
                    <Tooltip title="You can modify the image for commercial purposes as long as you give credit to the creator.">
                      <div>Free to Modify, Share, and Use Commercially</div>
                    </Tooltip>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Conditionally render the input based on the search mode */}
          {searchMode === "text" && (
            <TextField
              fullWidth
              label="Enter search query"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              variant="outlined"
              sx={{ marginBottom: "1rem" }}
              InputProps={{
                style: {
                  borderRadius: "50px", // Add borderRadius here for the input field
                },
              }}
            />
          )}

          {searchMode === "image" && (
            <div style={{ marginBottom: "1rem" }}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
            </div>
          )}

          {/* Search button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => searchImages(false)} // Fresh search
            disabled={
              loading ||
              (searchMode === "text" && !query) ||
              (searchMode === "image" && !imageFile)
            }
            sx={{ marginBottom: "2rem", padding: "10px 20px", borderRadius: "8px" }}
          >
            {loading ? <CircularProgress size={24} /> : "Search Images"}
          </Button>

          {/* Display results */}
          {results.length > 0 && (
            <>
              <Grid container spacing={2}>
                {results.map((result, index) => {
                  const imageLink = result.contentUrl || result.thumbnailUrl;
                  if (!imageLink) return null; // Skip rendering if no valid image link is found.

                  // Map license codes to user-friendly descriptions
                  const licenseMapping = {
                    Public: "Public Domain",
                    Share: "Free to Share and Use",
                    ShareCommercially: "Free to Share and Use Commercially",
                    Modify: "Free to Modify, Share, and Use",
                    ModifyCommercially: "Free to Modify, Share, and Use Commercially",
                    All: "All Licenses",
                  };

                  const licenseInfo =
                    licenseMapping[result.license] || "License information not available";
                  const licenseLink = result.hostPageUrl || imageLink;

                  return (
                    <Grid item xs={12} sm={6} md={4} key={imageLink}>
                      <Card sx={{ height: "100%" }}>
                        <CardContent>
                          <Checkbox
                            checked={selectedImages.includes(imageLink)}
                            onChange={() => handleSelectImage(imageLink)}
                          />
                          <img
                            src={imageLink}
                            alt={result.name || "Image"}
                            style={{
                              width: "100%",
                              borderRadius: "8px",
                              marginBottom: "1rem",
                            }}
                            onClick={() => handleImageClick(imageLink)} // Open the viewer when clicked
                          />
                          {/* Removed the License Info text */}
                          {/* Changed the button text to "Visit Link" */}
                          <Button
                            variant="text"
                            size="small"
                            href={licenseLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Visit Link
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>

              {/* Load More button below the images */}
              {hasMore && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => searchImages(true)} // Load more results
                  sx={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    padding: "10px 20px",
                    borderRadius: "8px",
                  }}
                >
                  Load More
                </Button>
              )}

              {selectedImages.length > 0 && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={downloadSelectedImages}
                    sx={{
                      marginTop: "2rem",
                      marginBottom: "2rem",
                      marginLeft: "2rem",
                      padding: "10px 20px",
                      borderRadius: "8px",
                    }}
                  >
                    Download
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      navigate("/classify-save", { state: { images: selectedImages } })
                    }
                    sx={{
                      marginTop: "2rem",
                      marginBottom: "2rem",
                      marginLeft: "2rem",
                      padding: "10px 20px",
                      borderRadius: "8px",
                    }}
                  >
                    Save Selected Images
                  </Button>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>

      {/* Image Viewer Dialog */}
      <Dialog
        open={Boolean(selectedImage)}
        onClose={() => setSelectedImage(null)} // Close the dialog when clicked outside
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              style={{ width: "100%", borderRadius: "8px" }}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Explanatory Section */}
      <Box sx={{ textAlign: "center", width: "100%", marginTop: "4rem" }}>
        <ImageSearchIcon sx={{ fontSize: "3rem", color: "#550FCC" }} />{" "}
        {/* Icon for explanation */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
          Web Image Search Tool
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
          Use this tool to search for images stored in a large web image dataset. You can search by
          both text queries and image uploads. Filter results based on similarity or date, and
          download your selected images easily.
        </Typography>
      </Box>
    </Container>
  );
};

export default SearchAndRetrieveWebImages;
