import React, { useContext } from 'react';
import { Button, Typography, Container, Grid, Card, CardContent, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const TurboTagInfo = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleCTAClick = () => {
    if (user) {
      navigate('/create-model');
    } else {
      navigate('/login');
    }
  };

  // Define your color palette here (matching Home component)
  const colors = {
    primary: "#550FCC",
    secondary: "#8338ec",
    background: "#f0f7ff",
    text: "#2b2d42",
    accent: "#ff006e",
    featureBackground: "#1a237e", // Deep blue for feature cards
  };

  const features = [
    {
      icon: "🚀",
      title: "Custom Model Creation",
      description: "Build tailored image classification models with just a few clicks."
    },
    {
      icon: "🔍",
      title: "Intelligent Image Search",
      description: "Find similar images quickly using our advanced AI-powered search."
    },
    {
      icon: "⚡",
      title: "Rapid Classification",
      description: "Classify thousands of images in minutes, not hours."
    },
    {
      icon: "👁️",
      title: "Visual Quality Control",
      description: "Automate quality inspections with high accuracy."
    },
  ];

  const industries = [
    {
      title: "E-commerce",
      description: "Automate product tagging and improve search relevance."
    },
    {
      title: "Manufacturing",
      description: "Enhance quality control processes with visual inspections."
    },
    {
      title: "Healthcare",
      description: "Assist in medical image analysis and diagnosis."
    },
    {
      title: "Agriculture",
      description: "Monitor crop health and detect pests or diseases early."
    },
  ];

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        width: "100%",
        paddingLeft: "0",
        paddingRight: "0",
        marginTop: "4rem"
      }}
    >
      {/* Hero Section with Features */}
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#E3F2FD",
          paddingTop: "4rem",
          paddingBottom: "4rem",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ fontWeight: "bold", marginBottom: "1rem", color: colors.primary }}>
                TurboTag: Custom AI Models in Seconds
              </Typography>
              <Typography variant="h5" sx={{ marginBottom: "2rem", color: colors.text }}>
                Build, share, deploy and use custom image classification models with ease.
              </Typography>
              <Button
                variant="contained"
                onClick={handleCTAClick}
                sx={{
                  padding: "12px 24px",
                  fontSize: "1.2rem",
                  borderRadius: "8px",
                  backgroundColor: colors.accent,
                  "&:hover": {
                    backgroundColor: colors.primary,
                  },
                }}
              >
                {user ? 'Create Your Model' : 'Get Started'}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {features.map((feature, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Card sx={{ 
                      height: '100%', 
                      backgroundColor: colors.featureBackground, 
                      color: '#FFFFFF',
                      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                      }
                    }}>
                      <CardContent>
                        <Typography variant="h4" sx={{ marginBottom: "0.5rem" }}>
                          {feature.icon}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
                          {feature.title}
                        </Typography>
                        <Typography variant="body2">
                          {feature.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Industry Applications Section */}
      <Container maxWidth="lg" sx={{ marginTop: "4rem" }}>
        <Typography variant="h3" sx={{ fontWeight: "bold", color: colors.primary, marginBottom: "2rem", textAlign: "center" }}>
          Transforming Industries with TurboTag
        </Typography>
        <Grid container spacing={4}>
          {industries.map((industry, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Card sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: '#F0EFEA',
                padding: "2rem",
                borderRadius: "12px",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}>
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "1rem", color: colors.text }}>
                    {industry.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: colors.text }}>
                    {industry.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Call-to-Action Section */}
      <Box sx={{
        width: "100%",
        backgroundColor: colors.primary,
        marginTop: "4rem",
        padding: "4rem 0",
        color: "white",
        textAlign: "center",
      }}>
        <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "1rem" }}>
          Ready to Build Your Custom AI Model?
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: "2rem" }}>
          Start creating powerful image classification models in minutes.
        </Typography>
        <Button
          variant="contained"
          onClick={handleCTAClick}
          sx={{
            padding: "12px 24px",
            fontSize: "1.2rem",
            borderRadius: "8px",
            backgroundColor: "white",
            color: colors.secondary,
            "&:hover": {
              backgroundColor: colors.accent,
              color: "white",
            },
          }}
        >
          {user ? 'Create Your Model' : 'Get Started'}
        </Button>
      </Box>
    </Container>
  );
};

export default TurboTagInfo;