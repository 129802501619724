import axios from 'axios';

const api = axios.create({
  baseURL: "https://netrasys.ai",  // Replace with your FastAPI backend URL
});

// Add token to every request if available
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  
  // Set Authorization header if token exists
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // Only set Content-Type to 'application/json' if not sending FormData
  if (!(config.data instanceof FormData)) {
    config.headers["Content-Type"] = "application/json";
  }
  
  return config;
});

export default api;
