// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from '../api';  // Ensure your axios instance is set up to send the token

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // On component mount, check if there is a stored token and set user accordingly
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Fetch user information if token exists
      axios.get('/me')  // Assuming your backend has a /me endpoint to get current user
        .then(response => {
          setUser(response.data);
        })
        .catch(() => {
          localStorage.removeItem('token');  // If token is invalid, remove it
          setUser(null);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (username, password) => {
    // Create a FormData object to send username and password as form-data
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    // Send the login request using form-data
    const response = await axios.post('/login', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',  // Ensure correct headers
      },
    });

    // Store the access token in localStorage
    localStorage.setItem('token', response.data.access_token);

    // Set the default Authorization header for future requests
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`; 

    // Fetch and set the current user details
    const userResponse = await axios.get('/me');
    setUser(userResponse.data);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
