// src/components/ForgotUsername.js

import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Paper } from "@mui/material";
import axios from 'axios';
import api from "../api"; // Import your API configuration

const ForgotUsername = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(''); // To display success or error messages
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to your backend
      const response = await api.post(`/forgot-username`, {
        email: email,
      });

      setMessage(response.data.message);
      setError('');
    } catch (error) {
      console.error('Error requesting username:', error);
      setError('An error occurred. Please try again later.');
      setMessage('');
    }
  };

  return (
    <Container 
      maxWidth="sm" 
      sx={{ 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ padding: "2rem", borderRadius: "12px", width: "100%" }}>
        <Box 
          sx={{ 
            mb: 2, 
            textAlign: "center",
            color: "#550FCC"
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
            Forgot Username
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Please enter your email address to retrieve your username.
          </Typography>
        </Box>

        {message && (
          <Typography color="primary" sx={{ mb: 2, textAlign: "center" }}>
            {message}
          </Typography>
        )}

        {error && (
          <Typography color="error" sx={{ mb: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          <TextField 
            fullWidth
            label="Email" 
            variant="outlined" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            margin="normal"
            InputProps={{
              sx: { borderRadius: "8px" }
            }}
          />

          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            fullWidth
            sx={{
              padding: "12px",
              borderRadius: "8px",
              mt: 2,
              backgroundColor: "#550FCC",
              '&:hover': {
                backgroundColor: "#8338ec"
              }
            }}
          >
            Submit
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default ForgotUsername;
