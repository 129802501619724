import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { TextField, Button, Container, Typography, Box, Paper } from "@mui/material";

const SignUp = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation: ensure all fields are filled
    if (!username || !password || !email) {
      setError("All fields are required.");
      return;
    }

    // Use FormData to send data as 'application/x-www-form-urlencoded'
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("email", email);

    try {
      // Make the API request to register a new user
      await api.post("/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",  // Important for form data
        },
      });
      alert("User registered successfully! Please log in.");
      navigate("/login");  // Redirect to login page after successful registration
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        // Set backend error message if available
        setError(error.response.data.detail);
      } else {
        setError("Failed to register. Please try again.");
      }
    }
  };

  return (
    <Container 
      maxWidth="sm" 
      sx={{ 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: "100vh", 
       // backgroundColor: "#f0f4f8"
      }}
    >
      <Paper elevation={3} sx={{ padding: "2rem", borderRadius: "12px", width: "100%" }}>
        <Box 
          sx={{ 
            mb: 2, 
            textAlign: "center",
            color: "#550FCC"
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
            Create Your Account
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Fill in your details to sign up
          </Typography>
        </Box>

        {error && (
          <Typography color="error" sx={{ mb: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          <TextField 
            fullWidth
            label="Username" 
            variant="outlined" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            margin="normal"
            InputProps={{
              sx: { borderRadius: "8px" }
            }}
          />
          <TextField 
            fullWidth
            label="Email" 
            variant="outlined" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            margin="normal"
            InputProps={{
              sx: { borderRadius: "8px" }
            }}
          />
          <TextField 
            fullWidth
            type="password" 
            label="Password" 
            variant="outlined" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            margin="normal"
            InputProps={{
              sx: { borderRadius: "8px" }
            }}
          />

          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            fullWidth
            sx={{
              padding: "12px",
              borderRadius: "8px",
              mt: 2,
              backgroundColor: "#550FCC",
              '&:hover': {
                backgroundColor: "#8338ec"
              }
            }}
          >
            Sign Up
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default SignUp;
