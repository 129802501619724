import React, { useState, useContext } from 'react';
import { TextField, Button, Container, Typography, Box, Paper, Link } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';  // Import AuthContext

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");  // To store and display error messages
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);  // Use login from AuthContext

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call login from AuthContext, which will handle storing the token
      await login(username, password);
      navigate("/");  // Redirect to home page after successful login
    } catch (error) {
      console.error("Login failed:", error);
      setError("Login failed! Please check your credentials.");  // Set error message for display
    }
  };

  return (
    <Container 
      maxWidth="sm" 
      sx={{ 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ padding: "2rem", borderRadius: "12px", width: "100%" }}>
        <Box 
          sx={{ 
            mb: 2, 
            textAlign: "center",
            color: "#550FCC"
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
            Welcome Back!
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Please login to continue
          </Typography>
        </Box>

        {error && (
          <Typography color="error" sx={{ mb: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          <TextField 
            fullWidth
            label="Username" 
            variant="outlined" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            margin="normal"
            InputProps={{
              sx: { borderRadius: "8px" }
            }}
          />
          <TextField 
            fullWidth
            type="password" 
            label="Password" 
            variant="outlined" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            margin="normal"
            InputProps={{
              sx: { borderRadius: "8px" }
            }}
          />

          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            fullWidth
            sx={{
              padding: "12px",
              borderRadius: "8px",
              mt: 2,
              backgroundColor: "#550FCC",
              '&:hover': {
                backgroundColor: "#8338ec"
              }
            }}
          >
            Login
          </Button>
        </form>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link href="/forgot-password" variant="body2" sx={{ mr: 2 }}>
            Forgot Password?
          </Link>
          <Link href="/forgot-username" variant="body2">
            Forgot Username?
          </Link>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;
