import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, Paper } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import api from "../api"; // Import your API configuration

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");  // To display success or error messages
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the token from the URL query parameter
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    if (!token) {
      setError("Invalid or missing token.");
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      // Call the reset password endpoint
      const response = await api.post('/reset-password', { token, new_password: newPassword });
      setMessage(response.data.message);
      setError("");
    } catch (error) {
      console.error("Reset password failed:", error);
      setError(error.response?.data?.detail || "An error occurred while resetting password.");
      setMessage("");
    }
  };

  return (
    <Container 
      maxWidth="sm" 
      sx={{ 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ padding: "2rem", borderRadius: "12px", width: "100%" }}>
        <Box 
          sx={{ 
            mb: 2, 
            textAlign: "center",
            color: "#550FCC"
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
            Reset Password
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Enter your new password
          </Typography>
        </Box>

        {message && (
          <Typography color="primary" sx={{ mb: 2, textAlign: "center" }}>
            {message}
          </Typography>
        )}

        {error && (
          <Typography color="error" sx={{ mb: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          <TextField 
            fullWidth
            label="New Password" 
            type="password"
            variant="outlined" 
            value={newPassword} 
            onChange={(e) => setNewPassword(e.target.value)} 
            margin="normal"
            InputProps={{
              sx: { borderRadius: "8px" }
            }}
          />
          <TextField 
            fullWidth
            label="Confirm Password" 
            type="password"
            variant="outlined" 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            margin="normal"
            InputProps={{
              sx: { borderRadius: "8px" }
            }}
          />

          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            fullWidth
            sx={{
              padding: "12px",
              borderRadius: "8px",
              mt: 2,
              backgroundColor: "#550FCC",
              '&:hover': {
                backgroundColor: "#8338ec"
              }
            }}
            disabled={!token}  // Disable the button if token is invalid
          >
            Reset Password
          </Button>
        </form>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button onClick={() => navigate('/login')} color="primary">
            Back to Login
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ResetPassword;
