import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  Paper,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import api from "../api";
import { useNavigate } from "react-router-dom";

const SearchAndRetrieveImages = () => {
  const [query, setQuery] = useState("");            // Text-based query state
  const [imageFile, setImageFile] = useState(null);  // Image file state for visual search
  const [results, setResults] = useState([]);        // Search results or default images
  const [loading, setLoading] = useState(false);     // Loading state
  const [error, setError] = useState("");            // Error state
  const [searchMode, setSearchMode] = useState("text");  // State for toggling between text and image search
  const [sortOrder, setSortOrder] = useState("date");  // Sorting option state
  const [pageNumber, setPageNumber] = useState(1);   // Pagination state for page number
  const [hasMore, setHasMore] = useState(true);      // State to track if there are more results
  const [suggestions, setSuggestions] = useState([]); // State for autocomplete suggestions
  const [selectedImages, setSelectedImages] = useState([]);  // Track selected images for download
  const [selectedImage, setSelectedImage] = useState(null); // To store the image URL for viewing

  // New state variables for sharing functionality
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [targetUsername, setTargetUsername] = useState("");

  // New state variable for top classes
  const [topClasses, setTopClasses] = useState([]);

  // Filter states (optional)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [classLabel, setClassLabel] = useState("");
  const [minSize, setMinSize] = useState("");
  const [maxSize, setMaxSize] = useState("");

  // State to differentiate between initial load and search
  const [isSearching, setIsSearching] = useState(false);

  const pageSize = 12;  // Number of results per page (adjust as needed)

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // New state variable for delete operation
  const [deleting, setDeleting] = useState(false);

  // Function to delete selected images
  const deleteSelectedImages = async () => {
    if (!selectedImages.length) {
      setError("Please select at least one image to delete.");
      return;
    }

    try {
      setDeleting(true); // Start deleting process
      setError(""); // Clear any previous errors

      // Call the backend API to delete selected images
      await api.delete("/images", {
        data: { image_urls: selectedImages },
      });

      // Filter out the deleted images from the `results`
      setResults((prevResults) =>
        prevResults.filter((result) => !selectedImages.includes(result.image_url))
      );

      // Clear selected images after deletion
      setSelectedImages([]);

      alert("Images deleted successfully");
    } catch (error) {
      setError("Error deleting images");
    } finally {
      setDeleting(false); // End deleting process
    }
  };

  // Fetch top classes on component mount
  useEffect(() => {
    fetchTopClasses();
    // Fetch initial images
    searchImages(false, true);  // Pass true for isInitialLoad
  }, []);

  const fetchTopClasses = async () => {
    try {
      const response = await api.get('/classes/top');
      setTopClasses(response.data.top_classes);
    } catch (error) {
      console.error('Error fetching top classes:', error);
    }
  };

  // Handle search mode switch
  const handleSearchMode = (event, newMode) => {
    if (newMode !== null) {
      setSearchMode(newMode);
      setQuery(""); // Clear query when switching modes
      setImageFile(null); // Clear image file when switching modes
      setResults([]); // Clear results
      setPageNumber(1); // Reset to first page
      setHasMore(true); // Reset "load more" state
      setError("");
      
      // Reload the default gallery view (sorted by date) when switching modes
      searchImages(false, true);
    }
  };
  

  // Function to handle sorting option change
  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
    // Re-fetch the images with the new sort order
    setResults([]);
    setPageNumber(1);
    setHasMore(true);
    searchImages(false, !isSearching);  // If not searching, it's initial load
  };

  // Fetch autocomplete suggestions
  const fetchAutocomplete = async (queryText) => {
    if (queryText.length < 3) {
      setSuggestions([]); // Clear suggestions if input is less than 3 characters
      return;
    }
    try {
      const response = await api.get(`/autocomplete`, { params: { query: queryText } });
      setSuggestions(response.data.suggestions);
    } catch (error) {
      console.error("Error fetching autocomplete:", error);
    }
  };

  // Fetch suggestions when the user types a query
  useEffect(() => {
    if (query.length > 2) {
      const debounce = setTimeout(() => fetchAutocomplete(query), 300);
      return () => clearTimeout(debounce); // Debounce to avoid excessive API calls
    }
  }, [query]);

  // When user clicks on a suggestion, set the query to the clicked suggestion
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]); // Clear suggestions after selecting one
  };

  const navigate = useNavigate();

  const goToMarketingContentGenerator = () => {
    navigate("/generate-marketing-content", { state: { selectedImages } });
  };

  // When user clicks on a suggestion, set the query to the clicked suggestion
  const searchImages = async (loadMore = false, isInitialLoad = false) => {
    if (!loadMore) {
      setLoading(true);
      if (!isInitialLoad && !query && !classLabel && !imageFile) {
        setError("Please enter a search query, select a class, or upload an image");
        setLoading(false);
        return;
      }
      setResults([]);
      setPageNumber(1);
      setHasMore(true);
    }
  
    setError("");
  
    let requestData = {};
    let headers = {};
    let endpoint = "/images/search"; // Default to text search
  
    // Image Search
    if (searchMode === "image" && imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile);
  
      // Optional filters
      if (startDate) formData.append("start_date", startDate.toISOString().split("T")[0]);
      if (endDate) formData.append("end_date", endDate.toISOString().split("T")[0]);
      if (classLabel) formData.append("class_label_filter", classLabel);
      if (minSize) formData.append("min_size", minSize);
      if (maxSize) formData.append("max_size", maxSize);
  
      requestData = formData;
      endpoint = "/images/visual-search"; // Visual search endpoint
    } else {
      // Text search or initial load
      const data = {};
      if (!isInitialLoad) {
        if (query) data.query_text = query;
        if (classLabel) data.class_label_filter = classLabel;
        if (startDate) data.start_date = startDate.toISOString().split("T")[0];
        if (endDate) data.end_date = endDate.toISOString().split("T")[0];
        if (minSize) data.min_size = minSize;
        if (maxSize) data.max_size = maxSize;
      }
      requestData = data;
      headers['Content-Type'] = 'application/json';
    }
  
    try {
      const response = await api.post(endpoint, requestData, {
        params: {
          page_size: pageSize,
          page_number: loadMore ? pageNumber : 1, // Load More pagination
        },
        headers: headers,
      });
  
      let fetchedResults = response.data.results;
  
      // Sort by date for initial load, similarity for searches
      const currentSortOrder = isInitialLoad ? 'date' : 'similarity';
      setSortOrder(currentSortOrder);
  
      if (currentSortOrder === 'date') {
        fetchedResults.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      }
  
      if (loadMore) {
        setResults((prevResults) => [...prevResults, ...fetchedResults]);
      } else {
        setResults(fetchedResults);
      }
  
      if (response.data.results.length < pageSize) {
        setHasMore(false);
      }
  
      setPageNumber((prevPage) => prevPage + 1);
  
    } catch (error) {
      setError("Error retrieving images");
    } finally {
      setLoading(false);
    }
  };
  

  // Handle selection of an image (checkbox)
  const handleSelectImage = (imageUrl) => {
    if (selectedImages.includes(imageUrl)) {
      setSelectedImages(selectedImages.filter((url) => url !== imageUrl)); // Remove if already selected
    } else {
      setSelectedImages([...selectedImages, imageUrl]); // Add to selected images
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl); // Set the clicked image URL to the state
  };

  // Download selected images as ZIP
  const downloadSelectedImages = async () => {
    try {
      const response = await api.post("/images/download-zip", { image_urls: selectedImages }, {
        headers: { 'Content-Type': 'application/json' },
        responseType: "blob", // Set response type to blob for file download
      });

      // Create a URL for the ZIP file and trigger download
      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "selected_images.zip";
      document.body.appendChild(a);
      a.click();
      a.remove(); // Clean up after download
    } catch (error) {
      setError("Error downloading images");
    }
  };

  // Functions to handle sharing dialog
  const handleOpenShareDialog = () => {
    setShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
    setTargetUsername("");
    setError("");
  };

  // Function to share selected images
  const shareSelectedImages = async () => {
    if (!targetUsername) {
      setError("Please enter a username to share with");
      return;
    }

    try {
      await api.post("/images/share", {
        image_urls: selectedImages,
        target_username: targetUsername,
      });

      // Show success message
      alert("Images shared successfully");

      // Close the dialog and reset state
      handleCloseShareDialog();
    } catch (error) {
      setError("Error sharing images");
    }
  };

  // Handle class click
  const handleClassClick = (classLabel) => {
    setClassLabel(classLabel);
    setQuery("");
    setSearchMode('text');
    setResults([]);
    setPageNumber(1);
    setIsSearching(true);
    searchImages(false);
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: "8rem" }}>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", marginBottom: "2rem", color: "#550FCC", textAlign: "center" }}
      >
        Search and Retrieve Images
      </Typography>

      {/* Top Classes Section */}
      {topClasses.length > 0 && (
        <Box sx={{ marginBottom: '2rem' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
            Top Classes
          </Typography>
          <Grid container spacing={1}>
            {topClasses.map((classItem, index) => (
              <Grid item key={index}>
                <Button
                  variant="outlined"
                  onClick={() => handleClassClick(classItem.class_label)}
                  sx={{ textTransform: 'none' }}
                >
                  {classItem.class_label} ({classItem.count})
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Grid container spacing={3}>
        {/* Left Column - Filters */}
        <Grid item xs={12} sm={3}>
          {/* Sorting Options */}
          <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
            <InputLabel id="sort-order-label">Sort By</InputLabel>
            <Select
              labelId="sort-order-label"
              id="sort-order"
              value={sortOrder}
              label="Sort By"
              onChange={handleSortOrderChange}
            >
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="similarity">Similarity</MenuItem>
            </Select>
          </FormControl>

          {/* Filters */}
          <TextField
            fullWidth
            label="Class Label"
            variant="outlined"
            value={classLabel}
            onChange={(e) => setClassLabel(e.target.value)}
            sx={{ marginBottom: "1.5rem" }}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => <TextField {...params} fullWidth sx={{ marginBottom: "1.5rem" }} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => <TextField {...params} fullWidth sx={{ marginBottom: "1.5rem" }} />}
            />
          </LocalizationProvider>

          <TextField
            fullWidth
            label="Min Size (KB)"
            variant="outlined"
            type="number"
            value={minSize}
            onChange={(e) => setMinSize(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">KB</InputAdornment>,
            }}
            sx={{ marginBottom: "1.5rem" }}
          />

          <TextField
            fullWidth
            label="Max Size (KB)"
            variant="outlined"
            type="number"
            value={maxSize}
            onChange={(e) => setMaxSize(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">KB</InputAdornment>,
            }}
            sx={{ marginBottom: "1.5rem" }}
          />
        </Grid>

        {/* Right Column - Search Toggle, Input, Button, Results */}
        <Grid item xs={12} sm={9}>
          {error && <Alert severity="error" sx={{ marginBottom: "1rem" }}>{error}</Alert>}

          {/* Toggle button for selecting search mode */}
          <ToggleButtonGroup
            color="primary"
            value={searchMode}
            exclusive
            onChange={handleSearchMode}
            sx={{ marginBottom: "1.5rem" }}
          >
            <ToggleButton value="text">Text Search</ToggleButton>
            <ToggleButton value="image">Visual Search</ToggleButton>
          </ToggleButtonGroup>

          {/* Conditionally render the input based on the search mode */}
          {searchMode === "text" && (
            <>
              <TextField
                fullWidth
                label="Enter search query"
                value={query}
                onChange={(e) => setQuery(e.target.value)}  // Allow free typing
                variant="outlined"
                sx={{ marginBottom: "1rem" }}
                InputProps={{
                  style: {
                    borderRadius: '50px' // Add borderRadius here for the input field
                  }
                }}
              />

              {/* Display Suggestions */}
              {suggestions.length > 0 && (
                <Paper>
                  <List>
                    {suggestions.map((suggestion, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}  // Set the input to the selected suggestion
                      >
                        <ListItemText primary={suggestion} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}
            </>
          )}

          {searchMode === "image" && (
            <div style={{ marginBottom: "1rem" }}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
            </div>
          )}

          {/* Search button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => searchImages(false, false)}  // User-initiated search
            disabled={loading || (searchMode === "text" && !query && !classLabel) || (searchMode === "image" && !imageFile)}
            sx={{ marginBottom: "2rem", padding: "10px 20px", borderRadius: "8px" }}
          >
            {loading ? <CircularProgress size={24} /> : "Search Images"}
          </Button>

          {/* Display results */}
          {results.length > 0 && (
            <>
              {/* Image Gallery */}
              <Grid container spacing={2}>
                {results.map((result, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <div onClick={() => handleImageClick(result.image_url)} style={{ cursor: 'pointer', position: 'relative' }}>
                      <img
                        src={`${result.image_url}?w=248&fit=crop&auto=format`}
                        srcSet={`${result.image_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={result.class_label}
                        style={{ width: '100%', height: 'auto', borderRadius: '8px' }}  // Ensures the image takes full width of the container and retains its aspect ratio
                        loading="lazy"
                      />
                      {/* Checkbox overlay */}
                      <Checkbox
                        checked={selectedImages.includes(result.image_url)}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleSelectImage(result.image_url);
                        }}
                        style={{
                          position: 'absolute',
                          top: '8px',
                          left: '8px',
                          color: 'white',
                        }}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>

              {/* Image Viewer Dialog */}
              <Dialog
                open={Boolean(selectedImage)}
                onClose={() => setSelectedImage(null)} // Close the dialog when clicked outside
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{ width: "100%", borderRadius: "8px" }}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    href={selectedImage}
                    download
                  >
                    Download Image
                  </Button>
                  <Button onClick={() => setSelectedImage(null)} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Download and Share Selected Images */}
              {selectedImages.length > 0 && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={downloadSelectedImages}
                    sx={{ marginTop: "2rem", padding: "10px 20px", borderRadius: "8px" }}
                  >
                    Download Selected Images
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenShareDialog}
                    sx={{ marginTop: "2rem", marginLeft: "1rem", padding: "10px 20px", borderRadius: "8px" }}
                  >
                    Share Selected Images
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={deleteSelectedImages}
                    disabled={deleting} // Disable button during delete process
                    sx={{ marginTop: "2rem", marginLeft: "1rem", padding: "10px 20px", borderRadius: "8px" }}
                  >
                    {deleting ? <CircularProgress size={24} /> : "Delete Selected Images"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={goToMarketingContentGenerator}
                    sx={{ marginTop: "2rem", marginLeft: "1rem", padding: "10px 20px", borderRadius: "8px" }}
                  >
                    Generate Marketing Content
                  </Button>
                </>
              )}

              {/* Load More button below the images */}
              {hasMore && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => searchImages(true, !isSearching)}  // Load more results
                  sx={{ marginTop: "2rem", marginBottom: "2rem", padding: "10px 20px", borderRadius: "8px" }}
                >
                  {loading ? <CircularProgress size={24} /> : "Load More"}
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>

      {/* Share Dialog */}
      <Dialog open={shareDialogOpen} onClose={handleCloseShareDialog}>
        <DialogTitle>Share Selected Images</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the username of the user you want to share the selected images with.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={targetUsername}
            onChange={(e) => setTargetUsername(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={shareSelectedImages} color="primary">
            Share
          </Button>
        </DialogActions>
      </Dialog>

      {/* Explanatory Section */}
      <Box sx={{ textAlign: "center", width: "100%", marginTop: "4rem" }}>
        <ImageSearchIcon sx={{ fontSize: "3rem", color: "#550FCC" }} />
        <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
          Why search and retrieve images?
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
          Designers and professionals can use this tool to quickly manage their image libraries,
          search for specific content, and retrieve images based on both text and visual queries.
          The added filters and sorting options allow you to refine your search for optimal results,
          making it easier to locate relevant images in large collections.
        </Typography>
      </Box>
    </Container>
  );
};

export default SearchAndRetrieveImages;