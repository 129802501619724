import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import api from "../api";

const ClassifyAndSaveImages = () => {
  const location = useLocation();
  const [files, setFiles] = useState([]); // Uploaded files
  const [externalImages, setExternalImages] = useState([]); // External image URLs
  const [classSuggestions, setClassSuggestions] = useState([]); // Suggested classes and tags
  const [userClasses, setUserClasses] = useState([]); // User-provided class names
  const [isClassified, setIsClassified] = useState(false); // Classification status
  const [loading, setLoading] = useState(false); // Loading status
  const [error, setError] = useState(""); // Error messages
  const [imageTags, setImageTags] = useState([]); // Tags per image
  const [newTags, setNewTags] = useState([]); // New tag input per image
  const [annotations, setAnnotations] = useState(""); // Annotation for images
  const [commonClass, setCommonClass] = useState(""); // Common class input
  const [models, setModels] = useState([]); // State to store models
  const [selectedModelId, setSelectedModelId] = useState(""); // State for selected model ID

  // Fetch models and handle external images passed via navigation
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await api.get("/models");
        setModels(response.data);
      } catch (error) {
        console.error("Failed to fetch models", error);
      }
    };

    fetchModels();

    if (location.state && location.state.images) {
      setExternalImages(location.state.images);
      setFiles([]); // Clear uploaded files when external images are provided
    }

    // Set selected model ID if passed via navigation
    if (location.state && location.state.selectedModelId) {
      setSelectedModelId(location.state.selectedModelId);
    }
  }, [location.state]);

  const classifyImages = async () => {
    setLoading(true);
    setError("");

    try {
      let response;
      if (externalImages.length > 0) {
        // Handle classification for external images (URLs)
        const data = {
          image_urls: externalImages,
          ...(selectedModelId && { model_id: selectedModelId }), // Include model_id if selected
        };

        response = await api.post("/images/classify", data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        // Handle classification for uploaded files
        const formData = new FormData();
        for (const file of files) {
          formData.append("files", file);
        }
        if (selectedModelId) {
          formData.append("model_id", selectedModelId);
        }

        response = await api.post("/images/classify", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      setClassSuggestions(response.data.classification_results);
      setIsClassified(true);
      setUserClasses(
        new Array(
          externalImages.length > 0 ? externalImages.length : files.length
        ).fill("")
      ); // Initialize user classes

      // Initialize image tags and newTags
      const resultsLength =
        externalImages.length > 0 ? externalImages.length : files.length;

      setImageTags(
        response.data.classification_results.map((result) => [
          ...(result.suggested_tags || []),
        ])
      );
      setNewTags(new Array(resultsLength).fill("")); // Initialize new tag inputs
    } catch (error) {
      setError("Error classifying images");
      console.error("Classification Error:", error);
      // Optionally, display detailed error messages from the backend
      if (error.response && error.response.data) {
        setError(
          "Error classifying images: " + JSON.stringify(error.response.data.detail)
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const saveImages = async () => {
    setLoading(true);
    setError("");

    try {
      if (externalImages.length > 0) {
        // Handle saving for external images (URLs)
        const data = {
          image_urls: externalImages,
          common_class: commonClass,
          annotations: annotations,
          class_names: userClasses.map(
            (className, i) =>
              className || commonClass || classSuggestions[i].suggested_class
          ),
          suggested_tags: imageTags, // Send per-image tags as an array
        };

        await api.post("/images/save-from-urls", data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        alert("Images saved successfully");
        // Reset states after saving
        setExternalImages([]);
        setClassSuggestions([]);
        setUserClasses([]);
        setIsClassified(false);
        setImageTags([]);
        setNewTags([]);
        setAnnotations("");
        setCommonClass("");
        setSelectedModelId("");
      } else {
        // Handle saving for uploaded files
        const formData = new FormData();

        // Append common data
        formData.append("common_class", commonClass);
        formData.append("annotations", annotations || "");

        // Append files and class names
        for (let i = 0; i < files.length; i++) {
          const className =
            userClasses[i] || commonClass || classSuggestions[i].suggested_class;
          formData.append("files", files[i]);
          formData.append("class_names", className); // Append each class name
        }

        // Append suggested_tags as JSON string
        formData.append("suggested_tags_json", JSON.stringify(imageTags));

        await api.post("/images/save", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        alert("Images saved successfully");
        // Reset states after saving
        setFiles([]);
        setClassSuggestions([]);
        setUserClasses([]);
        setIsClassified(false);
        setImageTags([]);
        setNewTags([]);
        setAnnotations("");
        setCommonClass("");
        setSelectedModelId("");
      }
    } catch (error) {
      setError("Error saving images");
      console.error("Save Images Error:", error);
      // Optionally, display detailed error messages from the backend
      if (error.response && error.response.data) {
        setError(
          "Error saving images: " + JSON.stringify(error.response.data.detail)
        );
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to add a new tag to a specific image
  const handleAddImageTag = (index) => {
    const tag = newTags[index].trim();
    if (tag && !imageTags[index].includes(tag)) {
      const updatedImageTags = [...imageTags];
      updatedImageTags[index].push(tag);
      setImageTags(updatedImageTags);

      const updatedNewTags = [...newTags];
      updatedNewTags[index] = "";
      setNewTags(updatedNewTags);
    }
  };

  // Function to delete a tag from a specific image
  const handleDeleteImageTag = (index, tagToDelete) => {
    const updatedImageTags = [...imageTags];
    updatedImageTags[index] = updatedImageTags[index].filter(
      (tag) => tag !== tagToDelete
    );
    setImageTags(updatedImageTags);
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
    // Reset previous classifications when new files are uploaded
    setClassSuggestions([]);
    setUserClasses([]);
    setIsClassified(false);
    setExternalImages([]); // Clear external images when new files are selected
    setImageTags([]);
    setNewTags([]);
  };

  return (
    <Container
      maxWidth="md"
      sx={{ marginTop: "8rem", textAlign: "center", marginBottom: "4rem" }}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", marginBottom: "2rem", color: "#550FCC" }}
      >
        Classify and Save Images
      </Typography>

      {error && (
        <Alert severity="error" sx={{ marginBottom: "2rem" }}>
          {error}
        </Alert>
      )}

      {/* Only show file input if no external images are provided */}
      {externalImages.length === 0 && (
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ marginBottom: "1rem" }}
        />
      )}

      {/* Model Selection */}
      <Box sx={{ marginBottom: "1rem", maxWidth: "500px", margin: "0 auto"  }}>
        <FormControl fullWidth>
          <InputLabel id="model-select-label">Select a Model (optional)</InputLabel>
          <Select
            labelId="model-select-label"
            value={selectedModelId}
            label="Select a Model (optional)"
            onChange={(e) => setSelectedModelId(e.target.value)}
          >
            <MenuItem value="">
              <em>Use Default Classification</em>
            </MenuItem>
            {models.map((model) => (
              <MenuItem key={model.id} value={model.id}>
                {model.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={classifyImages}
        disabled={loading || (files.length === 0 && externalImages.length === 0)}
        sx={{ marginTop:"1rem", marginBottom: "2rem", padding: "10px 20px", borderRadius: "8px" }}
      >
        {loading ? <CircularProgress size={24} /> : "Classify Images"}
      </Button>

      {isClassified && (
        <Box sx={{ marginTop: "2rem" }}>
          {/* Input for common class */}
          <TextField
            fullWidth
            label="Enter a common class for all images (optional)"
            variant="outlined"
            value={commonClass}
            onChange={(e) => setCommonClass(e.target.value)}
            sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          />

          {classSuggestions.map((suggestion, index) => (
            <Card key={index} sx={{ marginBottom: "1rem", padding: "1rem" }}>
              <CardContent>
                <Typography variant="h6">
                  <strong>Image:</strong>{" "}
                  {externalImages.length > 0
                    ? externalImages[index]
                    : files[index].name}
                </Typography>
                {/* Display the image */}
                <img
                  src={
                    externalImages.length > 0
                      ? externalImages[index]
                      : URL.createObjectURL(files[index])
                  }
                  alt={`Image ${index}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "200px",
                    marginTop: "1rem",
                  }}
                />
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                  <strong>Suggested Class:</strong>{" "}
                  {suggestion.suggested_class || "Unknown"}
                </Typography>

                {/* Input for user class */}
                <TextField
                  fullWidth
                  label="Enter your own class for this image (optional)"
                  variant="outlined"
                  value={userClasses[index]}
                  onChange={(e) => {
                    const newClasses = [...userClasses];
                    newClasses[index] = e.target.value;
                    setUserClasses(newClasses);
                  }}
                  sx={{ marginTop: "1rem" }}
                  placeholder={
                    commonClass || suggestion.suggested_class
                  } // Placeholder
                />

                {/* Display suggested tags if available */}
                {suggestion.suggested_tags && suggestion.suggested_tags.length > 0 && (
                  <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Typography variant="body1" sx={{ marginBottom: "0.5rem" }}>
                      <strong>Suggested Tags:</strong>
                    </Typography>
                    {suggestion.suggested_tags.map((tag, tagIndex) => (
                      <Chip
                        key={tagIndex}
                        label={tag}
                        onDelete={() => handleDeleteImageTag(index, tag)}
                        sx={{ marginRight: "8px", marginBottom: "8px" }}
                        color="primary"
                      />
                    ))}
                  </Box>
                )}

                {/* Input for adding new tags per image */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <TextField
                    label="Enter a tag"
                    value={newTags[index]}
                    onChange={(e) => {
                      const updatedNewTags = [...newTags];
                      updatedNewTags[index] = e.target.value;
                      setNewTags(updatedNewTags);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent form submission on Enter
                        handleAddImageTag(index);
                      }
                    }}
                    sx={{ marginTop:"0.5rem", marginRight: "1rem", flexGrow: 1 }}
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleAddImageTag(index)}
                    sx={{
                      marginTop:"0.5rem",
                      minWidth: '100px',
                      padding: "10px 10px",
                      borderRadius: "8px",
                    }}
                  >
                    Add Tag
                  </Button>
                </Box>

                {/* Display added tags per image */}
                <Box sx={{ marginBottom: "1rem" }}>
                  {imageTags[index].map((tag, tagIdx) => (
                    <Chip
                      key={tagIdx}
                      label={tag}
                      onDelete={() => handleDeleteImageTag(index, tag)}
                      sx={{ marginRight: "8px", marginBottom: "8px" }}
                      color="secondary"
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>
          ))}

          {/* Input for annotations */}
          <TextField
            label="Add annotations"
            value={annotations}
            onChange={(e) => setAnnotations(e.target.value)}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            sx={{ marginBottom: "2rem" }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={saveImages}
            disabled={loading}
            sx={{
              marginTop: "2rem",
              padding: "10px 20px",
              borderRadius: "8px",
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Save Images"}
          </Button>
        </Box>
      )}

      {/* Explanatory Section */}
      <Box sx={{ textAlign: "center", width: "100%", marginTop: "4rem" }}>
        <ImageSearchIcon sx={{ fontSize: "3rem", color: "#550FCC" }} />
        <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
          Why classify and save images?
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ mb: 4 }}
        >
          Whether you’re a designer looking to better manage your image library or a business
          professional organizing visual data, classifying and saving images helps you quickly
          search for specific content. Using tags and annotations enhances searchability and
          makes it easier to find exactly what you need, improving your workflow and productivity.
        </Typography>
      </Box>
    </Container>
  );
};

export default ClassifyAndSaveImages;
