import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import newHeroImage from "../assets/ai_model_image.jpg"; // Update with your new hero image path
import heroBackgroundImage from "../assets/netra8.png"

const Home = ({ setMode }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleTurboTag = () => {
    setLoading(true);
    setMode("TurboTag");
    setTimeout(() => {
      setLoading(false);
      if (user) {
        navigate("/turbo-tag-info");
      } else {
        navigate("/login");
      }
    }, 1000);
  };

  const handleSnapSort = () => {
    setLoading(true);
    setMode("SnapSort");
    setTimeout(() => {
      setLoading(false);
      if (user) {
        navigate("/snap-sort-info");
      } else {
        navigate("/login");
      }
    }, 1000);
  };

  // Add the new handler function for the CTA button
const handleTryNetraAI = () => {
  navigate('/public-features');  // Navigate to the public features page
};

  // Define your color palette here
  const colors = {
    primary: "#550FCC", // Primary color
    secondary: "#8338ec", // Secondary color
    background: "#f0f7ff",
    text: "#2b2d42", // Dark text color
    accent: "#ff006e",
    highlight: "#FF4500", //"#000000", // Highlight color for rotating words
    boxBackground: "#FFE4C4", //"#FFD700", // Background color for rolling word box
  };

  // Words to rotate in the first line of hero section
  const rotatingWords = ["Search", "Generate", "Classify", "Tag", "Organize"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % rotatingWords.length);
    }, 2000); // Change word every 2 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [rotatingWords.length]);


  // Benefits data
  const benefits = [
    {
      icon: "🚀",
      title: "Fast & Easy",
      description: "Build models in seconds without any coding.",
    },
    {
      icon: "💰",
      title: "Cost-Effective",
      description: "Save time and resources by automating image classification.",
    },
    {
      icon: "⚖️",
      title: "Scalable",
      description: "Manage large image datasets with ease.",
    },
    {
      icon: "🤝",
      title: "Collaborative",
      description: "Share and collaborate with others effortlessly.",
    },
  ];

  // Use cases data
  const useCases = [
    {
      title: "E-commerce",
      description:
        "Quickly tag and categorize products for faster search and discovery.",
    },
    {
      title: "Real Estate",
      description:
        "Organize and search property images effortlessly.",
    },
    {
      title: "Creatinve Agencies",
      description: "Efficiently manage and retrieve digital assets with AI.",
    },
    {
      title: "Agriculture",
      description:
        "Monitor crop health and optimize yield with image analysis.",
    },
  ];

  // Testimonials data
  const testimonials = [
    {
      quote:
        "Netra.AI saved us countless hours by automating our image classification process.",
      name: "John Doe",
      company: "Company XYZ",
    },
    {
      quote:
        "SnapSort has revolutionized how we manage and search our product images.",
      name: "Jane Smith",
      company: "Retailer ABC",
    },
  ];

  // New applicability data
  const applications = [
    {
      icon: "📸",
      title: "Photographers",
      description:
        "Automatically tag, organize, and search through your image library with AI-powered precision. Retrieve and generate new images effortlessly.",
    },
    {
      icon: "🛍️",
      title: "Retail Businesses",
      description:
        "Boost customer engagement with AI-driven visual search and product discovery. Classify, search, and generate images to enhance your e-commerce platform.",
    },
    {
      icon: "🏠",
      title: "Real Estate Agencies",
      description:
        "Easily organize, tag, and retrieve property images. Use AI to find similar listings and generate marketing visuals.",
    },
    {
      icon: "🎨",
      title: "Creative Agencies",
      description:
        "Manage, tag, and retrieve digital assets seamlessly. Use AI to find the right images and even generate new ones for your creative projects.",
    },
  ];

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        width: "100%",
        paddingLeft: "0",
        paddingRight: "0",
      }}
    >
      {/* Hero Section */}
      <Box
  sx={{
    width: "100%",
    height: { xs: "90vh", md: "100vh" },  // Adjust height for mobile (90vh) and larger screens (100vh)
    backgroundImage: `url(${heroBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.25)",  // Semi-transparent overlay
      zIndex: 1,
    },
  }}
>
  {/* Content Box */}
  <Box
    sx={{
      position: "relative",
      zIndex: 2,
      color: colors.text,
      padding: { xs: "0 1rem", md: "0 3rem" },  // Add padding for mobile screens
    }}
  >
    <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              marginBottom: "1rem",
              color: "#FDFBF7",
              fontSize: { xs: "2rem", md: "3rem" },
              display: "flex", // Display as flex to align the box and the rest of the sentence
              justifyContent: "center", // Center align the content
              alignItems: "center", // Align items vertically in the center
              flexWrap: "wrap", // Allows text to wrap and prevents overflow
            }}
          >
            {/* Highlighted Box for Rolling Word */}
            <Box
              sx={{
                display: "inline-block",
                backgroundColor: colors.boxBackground,
                borderRadius: "12px",
                padding: "0.5rem 1rem", // Adjust padding
                marginRight: "1rem", // Create space between the box and the rest of the text
                color: colors.highlight,
                fontWeight: "bold", // Make the text bold
                textAlign: "center",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", // Add a subtle shadow for better visibility
                whiteSpace: "nowrap", // Ensure the text doesn't wrap within the box
              }}
            >
              {rotatingWords[currentWordIndex]}
            </Box>
            Images with the Magic of Multimodal AI
          </Typography>
    <Typography
      variant="h6"  // Use h6 for smaller screens
      sx={{
        marginBottom: "2rem",
        color: "#FDFBF7",
        fontSize: { xs: "1.2rem", md: "1.8rem" },  // Adjust font size for mobile and larger screens
        maxWidth: { xs: "90%", md: "1200px" },
        margin: "0 auto",
        textAlign: "center",
        //fontSize: { xs: "1rem", md: "1.2rem" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <span>Netra.AI is a multimodal AI platform for managing your visual data most efficiently.</span>
      <span>Automatically tag, classify and search images or create your own images and classifiers. </span>
    </Typography>

    {/* <Typography
      variant="h6"
      sx={{
        marginBottom: "2rem",
        color: "#00FFFF",
        maxWidth: { xs: "90%", md: "1200px" },
        margin: "0 auto",
        textAlign: "center",
        fontSize: { xs: "1rem", md: "1.2rem" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <span>Instantly organize, tag, and classify images with AI-powered tools.</span>
      <span>Create custom models to automate your image management workflow.</span>
    </Typography> */}

    {/* Call-to-Action Buttons */}
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        justifyContent: "center",
        marginTop: "3rem",
        flexDirection: { xs: "column", sm: "row" },  // Stack buttons vertically on mobile, horizontally on larger screens
      }}
      InputProps={{
        style: {
          borderRadius: '50px' // Add borderRadius here for the input field
        }
      }}
    >
      <Button
        variant="contained"
        onClick={handleTryNetraAI}
        sx={{
          padding: { xs: "10px 20px", md: "12px 24px" },  // Adjust button padding for mobile and larger screens
          fontSize: { xs: "1rem", md: "1.2rem" },
          borderRadius: "8px",
          backgroundColor: colors.accent,
          "&:hover": {
            backgroundColor: colors.primary,
          },
        }}
      >
        {loading ? <CircularProgress size={24} /> : "Try Netra.AI"}
      </Button>

      {/* <Button
        variant="contained"
        onClick={handleTurboTag}
        sx={{
          padding: { xs: "10px 20px", md: "12px 24px" },  // Adjust button padding for mobile and larger screens
          fontSize: { xs: "1rem", md: "1.2rem" },
          borderRadius: "8px",
          backgroundColor: colors.secondary,
          "&:hover": {
            backgroundColor: colors.secondary,
          },
        }}
      >
        {loading ? <CircularProgress size={24} /> : "Create Custom Classifers"}
      </Button> */}
    </Box>
  </Box>
</Box>


      {/* Features Section */}
      <Container maxWidth="lg" id="features-section" sx={{ marginTop: "4rem" }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: colors.primary,
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          Discover Our Features
        </Typography>
        <Grid container spacing={4}>
          {/* SnapSort Feature Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                height: "100%",
                padding: "2rem",
                backgroundColor: '#F0EFEA',
                borderRadius: "12px",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              }}
            >
              <CardContent>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: colors.text,
                    marginBottom: "1rem",
                  }}
                >
                  SnapSort: Intelligent Image Organization
                </Typography>
                <Typography variant="body1" sx={{ color: colors.text }}>
                  Classify and store images in the cloud with AI-powered tagging. 
                  Search through your image library using text or visual queries, 
                  find and save web images, and generate new images from prompts.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* TurboTag Feature Card */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                height: "100%",
                padding: "2rem",
                backgroundColor: '#F0EFEA',
                borderRadius: "12px",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              }}
            >
              <CardContent>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: colors.text,
                    marginBottom: "1rem",
                  }}
                >
                  TurboTag: Custom AI Models in Seconds
                </Typography>
                <Typography variant="body1" sx={{ color: colors.text }}>
                  Upload your images, define classes, and let our AI generate a
                  custom image classification model for you in seconds. Share
                  your models, run inferences, and integrate with your
                  applications effortlessly.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
        </Grid>
      </Container>

      {/* Real-World Applications Section */}
      <Container maxWidth="lg" sx={{ marginTop: "4rem" }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: colors.primary,
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          How Can You Use Netra.AI?
        </Typography>
        <Grid container spacing={4}>
          {applications.map((app, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Box sx={{ textAlign: "center", padding: "1rem" }}>
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "1rem", fontSize: "3rem" }}
                >
                  {app.icon}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", marginBottom: "1rem" }}
                >
                  {app.title}
                </Typography>
                <Typography variant="body2" sx={{ color: colors.text }}>
                  {app.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* User Benefits Section */}
      <Container maxWidth="lg" sx={{ marginTop: "4rem" }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: colors.primary,
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          Why Choose Netra.AI?
        </Typography>
        <Grid container spacing={4}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Box sx={{ textAlign: "center", padding: "1rem" }}>
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "1rem", fontSize: "3rem" }}
                >
                  {benefit.icon}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", marginBottom: "1rem" }}
                >
                  {benefit.title}
                </Typography>
                <Typography variant="body2" sx={{ color: colors.text }}>
                  {benefit.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Use Cases Section */}
      <Container maxWidth="lg" sx={{ marginTop: "4rem" }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: colors.primary,
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          Transforming Industries with AI
        </Typography>
        <Grid container spacing={4}>
          {useCases.map((caseItem, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: '#F0EFEA',
                  color: "white",
                  padding: "2rem",
                  borderRadius: "12px",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", marginBottom: "1rem", color: colors.text }}
                  >
                    {caseItem.title}
                  </Typography>
                  <Typography variant="body1" sx={{color:colors.text}}>{caseItem.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Testimonials Section */}
      {/* <Container maxWidth="lg" sx={{ marginTop: "4rem" }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: colors.primary,
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          What Our Users Say
        </Typography>
        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  height: "100%",
                  padding: "2rem",
                  borderRadius: "12px",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  //backgroundColor: colors.background,
                }}
              >
                <CardContent>
                  <Typography
                    variant="body1"
                    sx={{ fontStyle: "italic", marginBottom: "1rem" }}
                  >
                    "{testimonial.quote}"
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    - {testimonial.name}, {testimonial.company}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container> */}

      {/* Final Call-to-Action Section */}
<Box
  sx={{
    width: "100%",  // Full width
    backgroundColor: colors.primary,  // Primary color background
    marginTop: "4rem",
    padding: "4rem 0",  // Top and bottom padding
    color: "white",
    textAlign: "center",
  }}
>
  <Typography
    variant="h3"
    sx={{
      fontWeight: "bold",
      marginBottom: "1rem",
    }}
  >
    Ready to Transform Your Image Management with AI?
  </Typography>
  <Typography variant="h6" sx={{ marginBottom: "2rem" }}>
    Sign up today and start leveraging the power of AI for your business.
  </Typography>

  <Button
    variant="contained"
    onClick={() => navigate("/signup")}
    sx={{
      padding: "12px 24px",
      fontSize: "1.2rem",
      borderRadius: "8px",
      backgroundColor: "white",
      color: colors.secondary,
      "&:hover": {
        backgroundColor: colors.accent,
        color: "white",
      },
    }}
  >
    Get Started for Free
  </Button>
</Box>

{/* Footer */}
<Box
  sx={{
    padding: "2rem",
    textAlign: "center",
   // marginTop: "4rem",
    backgroundColor: "#fff",  // Ensure footer background is white
  }}
>
  <Typography variant="body2" sx={{ color: colors.text }}>
    © {new Date().getFullYear()} Netra.AI. All rights reserved.
  </Typography>
  <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
    {/* Add social media icons and links here */}
  </Box>
</Box>

    </Container>
  );
};

export default Home;
