import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Card, 
  CardContent, 
  Button, 
  Stepper, 
  Step, 
  StepLabel, 
  Box,
  useTheme,
  styled
} from '@mui/material';

const steps = [
  "Sign Up or Log In",
  "Create a Model",
  "Add Images for Classes",
  "Train Your AI",
  "Test and Refine"
];

// Custom styled components
const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '16px',
  backgroundColor: theme.palette.background.default,
  boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.12)',
}));

const StepContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
}));

const Tutorial = () => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: theme.spacing(10), marginBottom: theme.spacing(6) }}>
      <StyledCard>
        <CardContent>
          <Typography variant="h3" sx={{ 
            fontWeight: "bold", 
            color: theme.palette.primary.main, 
            textAlign: "center",
            marginBottom: theme.spacing(4)
          }}>
            Build Your AI Model Step by Step
          </Typography>

          <Stepper 
            activeStep={activeStep} 
            alternativeLabel
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: theme.palette.secondary.main,
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: theme.palette.primary.main,
              },
            }}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <StepContent>
            {activeStep === 0 && (
              <>
                <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.secondary.main }} gutterBottom>
                  Step 1: Sign Up or Log In
                </Typography>
                <Typography variant="body1">
                  Create an account or log in to begin. It only takes a minute!
                </Typography>
              </>
            )}

            {activeStep === 1 && (
              <>
                <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.secondary.main }} gutterBottom>
                  Step 2: Create a Model
                </Typography>
                <Typography variant="body1">
                  Name your AI model and provide a description. This is the first step toward building your custom AI!
                </Typography>
              </>
            )}

            {activeStep === 2 && (
              <>
                <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.secondary.main }} gutterBottom>
                  Step 3: Add Images for Classes
                </Typography>
                <Typography variant="body1">
                  Upload images that represent the categories you want the AI to recognize, like "pizza," "burger," etc.
                </Typography>
              </>
            )}

            {activeStep === 3 && (
              <>
                <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.secondary.main }} gutterBottom>
                  Step 4: Train Your AI
                </Typography>
                <Typography variant="body1">
                  Let Netra.AI do the hard work! It will automatically train your AI using the images you uploaded.
                </Typography>
              </>
            )}

            {activeStep === 4 && (
              <>
                <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.secondary.main }} gutterBottom>
                  Step 5: Test and Refine
                </Typography>
                <Typography variant="body1">
                  Test your model by uploading new images. If needed, refine the AI by adding more images and retraining.
                </Typography>
              </>
            )}
          </StepContent>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: theme.spacing(4) }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
              sx={{ 
                padding: "12px 24px", 
                borderRadius: "8px",
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ 
                padding: "12px 24px", 
                borderRadius: "8px",
                backgroundColor: theme.palette.secondary.main,
                '&:hover': {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
              disabled={activeStep === steps.length - 1}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default Tutorial;