import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Paper } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from "../api"; // Import your API configuration

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");  // To display success or error messages
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call the forgot password endpoint
      const response = await api.post('/forgot-password', { email });
      setMessage(response.data.message);
      setError("");
    } catch (error) {
      console.error("Forgot password failed:", error);
      setError("An error occurred while requesting password reset.");
      setMessage("");
    }
  };

  return (
    <Container 
      maxWidth="sm" 
      sx={{ 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ padding: "2rem", borderRadius: "12px", width: "100%" }}>
        <Box 
          sx={{ 
            mb: 2, 
            textAlign: "center",
            color: "#550FCC"
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
            Forgot Password
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Enter your email to reset your password
          </Typography>
        </Box>

        {message && (
          <Typography color="primary" sx={{ mb: 2, textAlign: "center" }}>
            {message}
          </Typography>
        )}

        {error && (
          <Typography color="error" sx={{ mb: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          <TextField 
            fullWidth
            label="Email" 
            type="email"
            variant="outlined" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            margin="normal"
            InputProps={{
              sx: { borderRadius: "8px" }
            }}
          />

          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            fullWidth
            sx={{
              padding: "12px",
              borderRadius: "8px",
              mt: 2,
              backgroundColor: "#550FCC",
              '&:hover': {
                backgroundColor: "#8338ec"
              }
            }}
          >
            Send Reset Link
          </Button>
        </form>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button onClick={() => navigate('/login')} color="primary">
            Back to Login
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
